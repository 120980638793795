export const data = {
  sections: {
    features: [
      {
        heading: '## Time Tracking',
        subheading: "There's more than one way to track time",
        items: [
          {
            title: 'One-Click Timers',
            description:
              'Track time across the web app, desktop app, mobile app, or browser extension - with one click! All your entries will sync automatically.',
            image: '/feature-timers.png',
          },
          {
            title: 'Background Tracking',
            description:
              'Auto-track every application or website you use for more than 10 seconds with our Timeline feature - then turn that data into time entries.',
            image: '/feature-timeline.png',
          },
          {
            title: 'Calendar Integration',
            description:
              'Track time across the web app, desktop app, mobile app, or browser extension - with one click! All your entries will sync automatically.',
            image: '/feature-calendar.png',
          },
          {
            title: 'Autotracker',
            description:
              "For the forgetful among us, set-up Toggl Track to trigger time entry suggestions based on the software you're currently using.",
            image: '/feature-autotracker.png',
          },
        ],
      },
      {
        heading: '## Reporting',
        subheading: 'Powerful reporting to reveal actionable insights',
        items: [
          {
            title: 'Summary, Detailed, & Weekly Reports',
            description:
              'Select the level of detail you want to see, filter and sort your data, and create simple, client-ready visuals and reports via CSV or PDF.',
            image: '/feature-time-reports.png',
          },
          {
            title: 'Background Tracking',
            description:
              'Auto-track every application or website you use for more than 10 seconds with our Timeline feature - then turn that data into time entries.',
            image: '/feature-timeline.png',
          },
          {
            title: 'Schedule Reports to Email',
            description:
              'Schedule your favorite reports to regularly arrive in your inbox so you’ll always stay up to date without the need to log into Toggl.',
            image: '/feature-email-reports.png',
          },
          {
            title: 'Time Rounding',
            description:
              'Whether you track seconds, minutes, or 6-minute increments, automatically round time entries up or down to the nearest interval of your choice.',
            image: '/feature-rounding.png',
          },
        ],
      },
      {
        heading: '## Project & Revenue Tracking',
        subheading: 'Where planning and profits meet',
        items: [
          {
            title: 'Project Dashboard',
            description:
              'Forecast timelines, budgets, and track progress as you go. Spot problems early and fight off scope creep with easy-to-read visual data.',
            image: '/feature-project-dashboard.png',
          },
          {
            title: 'Billable Rates',
            description:
              'Assign billable rates by workspace, team member, project, or project member to clearly see what activities are generating revenue.',
            image: '/feature-billable.png',
          },
          {
            title: 'Project Estimates & Alerts',
            description:
              "Get an alert as your project approaches its estimated end, so you'll instantly know when you're nearing the red.",
            image: '/feature-project-alerts.png',
          },
          {
            title: 'Profits vs Labor Costs',
            description:
              'Assign labor costs by team member and compare your payroll to your billables to see which projects and clients are profitable at a glance.',
            image: '/feature-labor-cost.png',
          },
        ],
      },
      {
        heading: '## Team Scheduling & Management',
        subheading: 'When everyone’s on the same page...it’s magic',
        items: [
          {
            title: 'Team Dashboard',
            description:
              'Check in on your team, so you can see who is over capacity and who might be able to lend a hand.',
            image: '/feature-team-activity.png',
          },
          {
            title: 'Email Tracking Reminders',
            description:
              "Automate email reminders to gently nudge team members who haven't tracked their target number of hours.",
            image: '/feature-email-reports.png',
          },
          {
            title: 'Required Fields',
            description:
              'Say goodbye to manual auditing! Set required fields to ensure your team fills in all the information you need for accurate reporting.',
            image: '/feature-required.png',
          },
          {
            title: 'Time Audits',
            description:
              'Quickly find time entries that have not been assigned to a project or task, or that seem too short or too long.',
            image: '/feature-audits.png',
          },
        ],
      },
    ],
  },
}
