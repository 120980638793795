// @flow

import * as React from 'react'
import styled from '@emotion/styled'

import { Hero, Section, Card } from '@toggl/ui'
import { fonts } from '@toggl/style'

import IndexLayout from 'layouts'
import LayoutTrack from 'components/LayoutTrack'
import heroBg from 'images/uploads/homepage-hero-bg.jpg'
import videoSrc from 'images/uploads/bg-video.mp4'
import { Subnavigation, CallToAction, Button, hooks, text } from '@toggl/ui'
import togglTrackFeatures from 'images/uploads/toggl-track-features.jpg'
import integrationsDesktop from 'images/uploads/integrations-desktop.png'
import integrationsMobile from 'images/uploads/integrations-mobile.png'
import caseStudyImage from 'images/uploads/case-study-image.png'
import MDX from 'components/mdx'
import { data } from '../../../data/track/features'
import { colors, media } from '@toggl/style'
import { Link } from 'gatsby'
import togglTrackApps from 'images/uploads/toggl-track-apps.png'
import buttonWeb from 'images/uploads/button-web.png'
import buttonMobile from 'images/uploads/button-mobile.png'
import buttonDesktop from 'images/uploads/button-desktop.png'
import Signup from 'components/Signup'

const links = [
  {
    id: 'time-tracking',
    title: 'Time Tracking',
  },
  { id: 'reporting', title: 'Reporting' },
  { id: 'project-and-revenue-tracking', title: 'Project & Revenue Tracking' },
  {
    id: 'team-scheduling-and-management',
    title: 'Team Scheduling & Management',
  },
]

const FeaturesComponent = ({ data, id }) => {
  return (
    <Section.Block id={id} background={colors.darkPurple}>
      <Card.FeaturesWrapper>
        <MDX>{data.heading}</MDX>
        <SubHeadingWrapper>
          <MDX>{data.subheading}</MDX>
        </SubHeadingWrapper>
        <Card.Features>
          {data.items.map((feature, index) => {
            return (
              <Card.Feature
                key={index}
                title={feature.title}
                paragraph={feature.description}
                image={<img src={feature.image} alt={feature.title} />}
              />
            )
          })}
        </Card.Features>
      </Card.FeaturesWrapper>
    </Section.Block>
  )
}

const FeaturesPage = () => {
  const HeroComponent = ({ children }) => {
    return (
      <Hero.Wrapper bgImage={heroBg} bgVideo={videoSrc}>
        {children}

        <Hero.ContentWrapper>
          <Hero.Title centered>
            Everything <br /> you need in <br /> <Rotalic>one</Rotalic> package
          </Hero.Title>
        </Hero.ContentWrapper>
      </Hero.Wrapper>
    )
  }

  const [active, setActive] = React.useState(links[0].id)
  const isDesktop = hooks.useMediaQuery(media.mqbp[1])

  hooks.useIntersect({
    ids: links.map((link, i) => `section_${i}`),
    onIntersect: (id) => setActive(id),
    threshold: [0.5],
  })

  return (
    <IndexLayout
      title={'All the Time-Tracking Features You Dream Of... And More'}
      description={
        'Toggl Track will help you track your time, manage projects, coordinate with your team, and see your profitability - all backed up by powerful reporting.'
      }
      ogImage={togglTrackFeatures}
    >
      <LayoutTrack Hero={HeroComponent}>
        <Subnavigation.Wrapper>
          {links.map(({ id, title }) => (
            <Subnavigation.Link
              active={id === active}
              key={id}
              onClick={() => setActive(id)}
            >
              {title}
            </Subnavigation.Link>
          ))}
        </Subnavigation.Wrapper>
        <FeaturesComponent id={'section_1'} data={data.sections.features[0]} />
        <Diagonal>
          <CtaWrapper>
            <CallToAction
              size={2}
              align={'left'}
              color={colors.darkPurple}
              title={<span>Over 100+ App Integrations</span>}
              subTitle={
                <span>
                  Toggl Track Button is a browser extension that allows you to
                  start the timer directly from online tools like Asana,Todoist,
                  Trello and more. With features like the Pomodoro Timer, idle
                  detection, and tracking reminders, it’s the ultimate tool to
                  help you stay focused on work.
                  <br />
                  Currently available on Chrome and Firefox.
                </span>
              }
              Cta={() => (
                <Link to={'/track/integrations/'}>
                  <Button.Alternate>See all integrations</Button.Alternate>
                </Link>
              )}
            />
            <Graphic>
              <img
                src={isDesktop ? integrationsDesktop : integrationsMobile}
                alt={'integrations'}
              />
            </Graphic>
          </CtaWrapper>
        </Diagonal>
        <FeaturesComponent id={'section_2'} data={data.sections.features[1]} />
        <Section.Block>
          <QuoteSection>
            <StyledQuote>
              <text.H2 color={colors.pink}>
                Users <em>love</em> us!
              </text.H2>
              <text.P1>And we know you will too</text.P1>
            </StyledQuote>
            <Quote>
              <Card.Large background={colors.darkPurple}>
                <StyledCardContent>
                  <text.H4>
                    "Toggl runs our whole business in terms of how we bill
                    clients and generate revenue."
                  </text.H4>
                  <text.P2>
                    Learn how software consultancy FullStack Labs uses Toggl
                    Track to build business.
                  </text.P2>
                  <a
                    href={'https://toggl.com/customers/fullstack-labs/'}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Button.Secondary>Read the case study</Button.Secondary>
                  </a>
                </StyledCardContent>
                <StyledImage>
                  <img src={caseStudyImage} alt={'case study'} />
                </StyledImage>
              </Card.Large>
            </Quote>
            <StyledTestimonials>
              <StyledT>
                <text.P3 color={colors.darkPurple}>
                  “Toggl increased our profitability by at least 20%. We found
                  out where the team was spending too much time on clients.”
                </text.P3>
                <a
                  href={'https://toggl.com/customers/fullstack-labs/'}
                  target="_blank"
                  rel="noreferrer"
                >
                  <Button.Secondary color={colors.darkPurple}>
                    Read the case study
                  </Button.Secondary>
                </a>
              </StyledT>
              <StyledT>
                <text.P3 color={colors.darkPurple}>
                  “Toggl keeps me focused. I can say goodbye to the guilt that
                  comes with multi-tasking or going off-topic.”
                </text.P3>
                <a
                  href={'https://toggl.com/customers/mediacurrent/'}
                  target="_blank"
                  rel="noreferrer"
                >
                  <Button.Secondary color={colors.darkPurple}>
                    Read the case study
                  </Button.Secondary>
                </a>
              </StyledT>
              <StyledT>
                <text.P3 color={colors.darkPurple}>
                  “Toggl has given us actual data about our labor; we're able to
                  see how much time each step of our creative process takes.”
                </text.P3>
                <a
                  href={'https://toggl.com/customers/roguemark-studios/'}
                  target="_blank"
                  rel="noreferrer"
                >
                  <Button.Secondary color={colors.darkPurple}>
                    Read the case study
                  </Button.Secondary>
                </a>
              </StyledT>
            </StyledTestimonials>
            <Link to={'/track/signup/'}>
              <Button.Primary>Try Toggl Track for free</Button.Primary>
            </Link>
          </QuoteSection>
        </Section.Block>
        <FeaturesComponent id={'section_3'} data={data.sections.features[2]} />
        <FeaturesComponent id={'section_4'} data={data.sections.features[3]} />
        <Diagonal background={colors.lightPink}>
          <CtaWrapperApps>
            {isDesktop && (
              <GraphicApps>
                <img src={togglTrackApps} alt={'Toggl Track Apps'} />
              </GraphicApps>
            )}
            <StyledCta>
              {' '}
              <CallToAction
                size={2}
                align={'left'}
                color={colors.darkPurple}
                title={
                  <text.H4 color={colors.pink}>
                    Toggl Track fits your workflow
                  </text.H4>
                }
                subTitle={
                  <text.P3 color={colors.darkPurple}>
                    <br />
                    <br />
                    Start the timer on your phone, stop it in your desktop app,
                    and then view your time reports on the web. The best part?
                    Your data instantly syncs across all apps and devices.
                    <br />
                    <br />
                    <br />
                    Online, offline, or on-the-go...we have an app for that!
                    <br />
                    <br />
                  </text.P3>
                }
              />
              <StyledButtons>
                <StyledButton>
                  <img
                    src={buttonWeb}
                    alt={'Toggl Track Browser Integration'}
                  />
                  <text.H4>Try the Web App for free</text.H4>
                </StyledButton>
                <StyledButton>
                  <img src={buttonDesktop} alt={'Toggl Track Desktop App'} />
                  <text.H4>Get the desktop app</text.H4>
                </StyledButton>
                <StyledButton>
                  <img src={buttonMobile} alt={'Toggl Track Mobile Apps'} />
                  <text.H4>Get the mobile app</text.H4>
                </StyledButton>
              </StyledButtons>
            </StyledCta>
          </CtaWrapperApps>
        </Diagonal>
        <Section.Block>
          <StyledSignup>
            <text.H2>
              Let us help you get on <em>track</em>
            </text.H2>
            <Signup />
          </StyledSignup>
        </Section.Block>
      </LayoutTrack>
    </IndexLayout>
  )
}

const StyledSignup = styled.div`
  h2 {
    text-align: center;
    em {
      color: ${(p) => p.theme.primary};
    }
  }
`

const StyledButtons = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 20px;
`

const StyledButton = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  h3,
  h4,
  h5 {
    color: ${colors.darkPurple};
    margin-left: 20px;
  }

  margin-bottom: 20px;

  img {
    width: 60px;
    height: 60px;
  }
`

const StyledCta = styled.div``

const StyledT = styled.div`
  padding: 26px;
  width: 30%;
  background: ${colors.beige};
  display: flex;
  flex-direction: column;

  a {
    display: block;
    margin-top: auto;
  }

  p {
    margin-bottom: 20px;
  }
`

const StyledImage = styled.div``
const StyledTestimonials = styled.div`
  display: flex;
  flex-direction: column;

  ${media.mq[1]} {
    flex-direction: row;
    justify-content: space-between;
  }
`

const StyledCardContent = styled.div`
  padding: 40px;
  display: flex;
  flex-direction: column;
  max-width: 80%;

  h4 {
    color: ${(p) => p.theme.primary};
  }

  p,
  a {
    margin-top: 40px;
  }
`

const Diagonal = styled.div`
  transform: skewY(10deg);
  background: ${(p) => p.background || colors.beige};
  overflow: hidden;

  ${media.mq[1]} {
    max-height: 600px;
  }
`

const CtaWrapper = styled.div`
  margin: 0 auto;
  display: flex;
  transform: skewY(-10deg);
  padding: 50px 30px;
  flex-direction: column;

  h2 {
    margin-bottom: 24px;
  }

  ${media.mq[1]} {
    max-width: 1200px;
    padding: 0;
    padding-top: 100px;
    flex-direction: row;
    > div:nth-child(1) {
      max-width: 50%;
    }
  }
`

const CtaWrapperApps = styled(CtaWrapper)`
  ${media.mq[1]} {
    > div:nth-child(2) {
      max-width: 50%;
    }
  }
`

const StyledQuote = styled.div`
  h2,
  p {
    text-align: center;
  }
`

const Graphic = styled.div`
  max-width: 550px;
  margin: 0 -50px;
  img {
    max-width: 100%;
    margin-top: 27px;
  }

  ${media.mq[1]} {
    padding-left: 150px;
    transform: translateY(-80px);
    img {
      max-width: 100%;
      margin-top: 0;
    }
  }
`

const GraphicApps = styled(Graphic)`
  ${media.mq[1]} {
    margin: 0;
    padding: 0;
    padding-right: 50px;
    img {
      transform: translateY(-120px);
    }
  }
`

const Quote = styled.div``
const QuoteSection = styled.div`
  h2 {
    text-align: center;
  }
`

const SubHeadingWrapper = styled.div`
  p {
    text-align: center;
    margin-top: 20px;
  }
`

const Rotalic = styled.span`
  font-family: ${fonts.gtHaptikMediumRotalic};
  color: ${(p) => p.theme.primary};
`

export default FeaturesPage
